<template>
  <v-dialog v-model="dialog" persistent max-width="700px">
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>Add schedule </span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <div class="form-block">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                    label="Name *"
                    v-model="exam_day.name"
                    outlined
                    :error="$v.exam_day.name.$error"
                    dense
                >
                  <template v-slot:label>
                    Name <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger" v-if="$v.exam_day.name.$error"
                >This information is required</span>

              </v-col>



              <v-col  cols="12" sm="12" md="6">
                <v-autocomplete
                    v-model="exam_day.location_id"
                    :items="locations"
                    filled
                    outlined
                    :error="$v.exam_day.location_id.$error"
                    dense
                    :loading="isLocationLoading"
                    item-text="name"
                    item-value="id"
                    @change="getExamSessions"
                >
                  <template v-slot:label>
                    Location <span class="text-danger">*</span>
                  </template>
                </v-autocomplete>


                <span class="text-danger" v-if="$v.exam_day.location_id.$error"
                >This information is required</span
                >
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <v-autocomplete
                    v-model="exam_day.exam_session_id"
                    :items="exam_sessions"
                    filled
                    outlined
                    :error="$v.exam_day.exam_session_id.$error"
                    dense
                    :loading="isExamSessionLoading"
                    item-text="name"
                    item-value="id"
                    @change="getVenues"
                >
                  <template v-slot:label>
                    Exam session <span class="text-danger">*</span>
                  </template>
                </v-autocomplete>

                <span class="text-danger" v-if="$v.exam_day.exam_session_id.$error"
                >This information is required</span
                >
              </v-col>

              <v-col cols="12" md="6">
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="exam_day.exam_date"
                        label="Exam date"
                        prepend-icon=""
                        prepend-inner-icon="mdi-calendar"
                        @input="exam_day.exam_date = $event !== null ? $event : ''"
                        :error="$v.exam_day.exam_date.$error"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                        clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="exam_day.exam_date"
                      no-title
                      @input="menu = false"
                  ></v-date-picker>
                  <span class="text-danger" v-if="$v.exam_day.exam_date.$error"
                  >This information is required</span>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <v-autocomplete
                    v-model="exam_day.venue_id"
                    :items="venues"
                    filled
                    outlined
                    :error="$v.exam_day.venue_id.$error"
                    dense
                    :loading="isVenueLoading"
                    item-text="name"
                    item-value="id"
                    @change="getRooms"
                >
                  <template v-slot:label>
                    Venue <span class="text-danger">*</span>
                  </template>
                </v-autocomplete>

                <span class="text-danger" v-if="$v.exam_day.venue_id.$error"
                >This information is required</span
                >
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <v-autocomplete
                    v-model="exam_day.room_id"
                    :items="rooms"
                    filled
                    outlined
                    :error="$v.exam_day.room_id.$error"
                    dense
                    :loading="isRoomLoading"
                    item-text="name"
                    item-value="id"
                >
                  <template v-slot:label>
                    Room <span class="text-danger">*</span>
                  </template>
                </v-autocomplete>

                <span class="text-danger" v-if="$v.exam_day.room_id.$error"
                >This information is required</span
                >
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <span><b>Note:</b> If the selected exam session and candidate's exam session doesn't match the candidate will not be assigned.</span>
              </v-col>




            </v-row>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            x-large
            @click="closeDialog"
        >Cancel</v-btn>

        <v-btn
            x-large
            dark
            data-kt-menu-dismiss="true"
            @click="createAndAssign"

        >
          Create and assign
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ExamSession from "@/services/practical-exam/exam-session/ExamSession";
import LocationService from "@/services/practical-exam/location/LocationService";
import RoomService from "@/services/practical-exam/venue/room/RoomService";
import VenueService from "@/services/practical-exam/venue/VenueService";
import SchedulerService from "@/services/practical-exam/scheduler/SchedulerService";

const location = new LocationService();
const examSession = new ExamSession();
const room = new RoomService();
const venue = new VenueService();
const scheduler = new SchedulerService();

import {required, requiredIf} from "vuelidate/lib/validators";

export default {
  validations: {
    exam_day: {
      name: { required },
      location_id: {required},
      room_id: {required},
      venue_id: {required},
      score_id: {required},
      exam_date: { required },
      exam_session_id: { required },
    },
  },
  props:[''],
  data(){
    return {
      dialog:false,
      exam_day:{
        name:'',
        score_id: "",
        room_id: "",
        venue_id: "",
        exam_session_id: "",
        location_id: "",
        candidate_ids:[],

      },
      scores: [],
      locations:[],
      exam_sessions:[],
      venues:[],
      rooms:[],
      menu: false,
      isScoreLoading:false,
      isLocationLoading:false,
      isExamSessionLoading:false,
      isRoomLoading:false,
      isVenueLoading:false,
    }
  },
  methods:{
    openDialog(candidate_ids,score_id)
    {

      this.exam_day.candidate_ids = candidate_ids;
      this.exam_day.score_id = score_id;
      this.dialog = true;
      this.getAllLocations();

    },

    closeDialog()
    {
      this.dialog = false;
    },

    resetForm()
    {
      this.exam_day = {
            name:'',
            score_id: "",
            room_id: "",
            venue_id: "",
            exam_session_id: "",
            location_id: "",
            candidate_ids:[],

      };
      this.dialog = false;
    },

    createAndAssign()
    {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        scheduler
            .createAndAssignCandidates(this.exam_day)
            .then((response) => {
              this.$snotify.success('Candidates assigned');
              this.$emit('refresh');
              this.resetForm();

            })
            .catch((err) => {
              this.$snotify.error('Problem occurred');
            })
      }
    },

    getAllLocations() {
      this.isLocationLoading = true;
      let data ={ score_id: this.exam_day.score_id };
      location
          .all(data)
          .then((response) => {
            this.locations = response.data.locations;
            this.isLocationLoading = false;
          })
          .catch((err) => {
          });
    },

    getExamSessions() {
      this.isExamSessionLoading = true;
      examSession
          .getByLocation(this.exam_day.location_id, this.exam_day.score_id)
          .then((response) => {
            this.exam_sessions = response.data.exam_sessions;
            this.isExamSessionLoading = false;
          })
          .catch((err) => {});
    },

    getVenues() {
      this.isVenueLoading = true;
      venue
          .getByLocation(this.exam_day.location_id)
          .then((response) => {
            this.venues = response.data;
            this.isVenueLoading = false;
          })
          .catch((err) => {});
    },

    getRooms() {
      this.isRoomLoading = true;
      room
          .getAllByVenue(this.exam_day.venue_id)
          .then((response) => {
            this.rooms = response.data.data;
            this.isRoomLoading = false;
          })
          .catch((err) => {});
    },

  },
  mounted() {
    this.getAllLocations();
  },

  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  }
};
</script>