<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Start scheduling</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Start scheduling
                </div>
              </div>
              <div class="breadcrumb-right">
                <div style="right: 5em; position:fixed; z-index: 5;" class="p-3">

                  <v-btn
                      v-if="candidate_ids.length>0"
                      style="color: #fff;"
                      @click.prevent="createAndAssignExamDay()"
                      class="mt-1 btn btn-primary"
                      :loading="isAssignLoading"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-list-check</v-icon>&nbsp;
                    Create schedule and assign candidates
                  </v-btn>
                  <v-btn
                    style="color: #fff;"
                    @click.prevent="assignExamDays()"
                    class="mt-1 ml-2 btn btn-primary"
                    :loading="isAssignLoading"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-check</v-icon>&nbsp;
                    Assign schedule
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <form  class="row">

                <v-col cols="12" sm="6" md="2" v-if="currentUser.access_type == 'federal'">
                  <v-autocomplete
                      @change="handleScoreChange"
                      label="SCORE"
                      v-model="search.score_id"
                      item-text="name"
                      item-value="id"
                      :items="scores" v-on:keyup.enter="searchCandidates()"
                      outlined clearable
                      dense
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>


                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Enrolment key"
                      v-model="search.exam_key" clearable
                      outlined  v-on:keyup.enter="searchCandidates()"
                      dense
                  ></v-text-field>
                </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-text-field
                        label="Candidate first name"
                        v-model="search.first_name" clearable
                        outlined  v-on:keyup.enter="searchCandidates()"
                        dense
                    ></v-text-field>
                  </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Candidate surname"
                      v-model="search.last_name" clearable
                      outlined  v-on:keyup.enter="searchCandidates()"
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-autocomplete
                      @change="selectExam"
                      v-model="selectedExam"
                      outlined
                      dense
                      :items="exams"
                      hide-selected
                      item-text="display_text"
                      :search-input.sync="examSearch"
                      return-object
                      label="Exams"
                      :loading="isLoading"
                      clearable
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">Start typing exam name </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-autocomplete
                      @change="selectSession"
                      v-model="selectedSession"
                      outlined
                      dense
                      :items="exam_sessions"
                      hide-selected
                      item-text="display_text"
                      :search-input.sync="sessionSearch"
                      return-object
                      label="Session"
                      :loading="isLoading"
                      clearable

                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">Start typing session name</div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-autocomplete
                      label="Grade"
                      v-model="search.grade_id"
                      item-text="name"
                      item-value="id"
                      :items="grades" v-on:keyup.enter="searchCandidates()"
                      :loading="isGradeLoading"
                      outlined clearable
                      dense
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Teacher name"
                      v-model="search.teacher_name" clearable
                      outlined  v-on:keyup.enter="searchCandidates()"
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-autocomplete
                      label="Scheduling request"
                      v-model="search.scheduling_request_id"
                      item-text="name"
                      item-value="id"
                      :items="scheduling_requests" v-on:keyup.enter="searchCandidates()"
                      outlined clearable
                      dense
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    outlined
                    dense
                    v-model="search.scheduling_remarks"
                    clearable
                    label="Scheduling remarks"
                    v-on:keyup.enter="searchCandidates()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-autocomplete
                      label="Category "
                      v-model="search.category_id"
                      item-text="name" v-on:keyup.enter="searchCandidates()"
                      item-value="id"
                      :items="categories"
                      outlined clearable
                      dense
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">Please select a week/session first</div>
                    </template>
                  </v-autocomplete>
                </v-col>


                  <v-col cols="12" sm="6" md="2">
                    <v-autocomplete
                        label="Instrument "
                        v-model="search.instrument_id"
                        item-text="name" v-on:keyup.enter="searchCandidates()"
                        item-value="id"
                        :items="instruments"
                        outlined clearable
                        :loading="isInstrumentLoading"
                        dense
                    >
                      <template v-slot:no-data>
                        <div class="no-data-auto-complete text-center">Please select a week/session first</div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  
                  <v-col cols="12" sm="6" md="2">
                    <v-autocomplete
                        label="Syllabus "
                        v-model="search.syllabus_id"
                        item-text="name" v-on:keyup.enter="searchCandidates()"
                        item-value="id"
                        :items="syllabuses"
                        outlined clearable
                        :loading="isSyllabusLoading"
                        dense
                    >
                      <template v-slot:no-data>
                        <div class="no-data-auto-complete text-center">Please select a week/session first</div>
                      </template>
                    </v-autocomplete>
                  </v-col>


                <v-col cols="12" sm="6" :md="currentUser.access_type == 'score' ? 2 : 2">
                  <v-autocomplete
                      label="Location"
                      v-model="search.location_id"
                      item-text="name" v-on:keyup.enter="searchCandidates()"
                      item-value="id"
                      :items="locations"
                      :loading="isLoading"
                      @change="handleLocationChange"
                      outlined clearable
                      dense
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" :md="currentUser.access_type == 'score' ? 2 : 2">
                  <v-autocomplete
                      label="Venue"
                      v-model="search.venue_id"
                      item-text="name" v-on:keyup.enter="searchCandidates()"
                      item-value="id"
                      :items="venues"
                      :loading="isVenueLoading"
                      outlined clearable
                      dense
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-select
                    outlined
                    dense
                    :items="sortByValues"
                    item-text="name"
                    item-value="value"
                    label="Sort by"
                    clearable
                    v-model="search.sort_by"
                  ></v-select>
                </v-col>



                  <v-col cols="12" sm="12" :md="currentUser.access_type == 'score' ? 6 : 4" class="text-right">
                  <v-btn
                      @click.prevent="searchCandidates()"
                      class="mt-1 btn btn-primary btn-search"
                      style="color: #fff;"
                      :loading="isSearching"
                  >
                      <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                  </v-btn>
                  </v-col>
              </form>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isContentLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isContentLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>

              <span class="ml-1" v-if="!isContentLoading"><b>Note:</b> If you have selected candidates using the tick boxes, the selections will not be saved if you move to the next page.  Please proceed with your action for this page, and repeat the process on  subsequent pages.</span>
              <table class="table" v-if="!isContentLoading">
                <thead>
                  <tr>
                    <th></th>
                    <th class="px-3" style="max-width: 350px !important; white-space: pre-wrap;">Full name</th>
                    <th style="max-width: 100px !important; white-space: pre-wrap;">Exam details</th>
                    <th style="max-width: 100px !important; white-space: pre-wrap;">Grade</th>
                    <th style="max-width: 100px !important; white-space: pre-wrap;">Location</th>
                    <th style="max-width: 100px !important; white-space: pre-wrap;">Venue</th>
                    <th style="max-width: 125px !important; white-space: pre-wrap;">Teacher name</th>
                    <th style="max-width: 125px !important; white-space: pre-wrap;">Week/session</th>
                    <th style="max-width: 350px !important;">Scheduling remarks</th>
                    <th style="max-width: 200px !important;">Unsuitable examiners</th>
                    <th style="min-width: 300px !important;">Schedule selection</th>
                  </tr>
                </thead>
                <tbody>
                  <template>
                  <tr v-for="(candidate,index) in candidates" :key="candidate.id" >
                    <td class="text-left">
                      <v-checkbox v-model="candidate_ids"   :value="candidate.id"></v-checkbox>
                    </td>
                    <td class="text-left" style="max-width: 350px !important;">
                      {{ candidate.full_name }}<br>
                      <a href="#" @click="getCandidateSummary(candidate.exam_key)" class="text-primary font-weight-bolder text-hover-primary mb-1"><u>{{candidate.exam_key}} </u>
                      </a>

                    </td>
                  <td class="text-left" style="max-width: 250px !important; white-space: pre-wrap;">
                    <p><b>Syllabus:</b> {{ candidate.syllabus_name ? candidate.syllabus_name  : 'NA'}}</p>
                    <p><b>Instrument:</b> {{ candidate.instrument_name ? candidate.instrument_name : 'NA' }}</p>
                  </td>

                    <td class="text-left" style="max-width: 100px !important; white-space: pre-wrap;">
                      {{ candidate.grade }}
                    </td>

                    <td class="text-left" style="max-width: 100px !important; white-space: pre-wrap;">
                      {{ candidate.location_name }}
                    </td>

                    <td class="text-left" style="max-width: 100px !important; white-space: pre-wrap;">
                      {{ candidate.venue_name_by_unique_code ? candidate.venue_name_by_unique_code : '-' }}
                    </td>

                    <td class="text-left" style="max-width: 125px !important; white-space: pre-wrap;">
                      {{ candidate.teacher_full_name }}
                    </td>

                    <td class="text-left" style="max-width: 125px !important; white-space: pre-wrap;">
                      {{ candidate.exam_session_name }}
                    </td>

                    <td class="text-left" style="max-width: 350px !important; white-space: pre-wrap;">
                      <p> {{ candidate.scheduling_request_name }}</p>
                      <p>
                        {{ candidate.scheduling_remarks }}
                      </p>
                    </td>

                    <td class="text-left" style="max-width: 200px !important; white-space: pre-wrap;">
                      {{ candidate.unsuitable_examiners }}
                    </td>

                    <td>
                      <v-autocomplete
                          placeholder="Schedule"
                          v-model="selected_exam_days[index].exam_day_id"
                          item-text="name"
                          item-value="id"
                          hide-selected
                          :items="candidate.applicable_exam_days"
                          outlined clearable
                          dense
                      ></v-autocomplete>
                    </td>


                     </tr>
                  <tr v-if="candidates.length == 0" >
                    <td colspan="11" class="text-center"><strong>No enrolments available to schedule</strong></td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                v-if="candidates.length > 0"
                class="pull-right mt-7"
                @input="getAllNotScheduledCandidates"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-schedule-and-assign-candidate  ref="create-schedule-and-assign-candidate" @refresh="getAllNotScheduledCandidates"></create-schedule-and-assign-candidate>
  </v-app>
</template>
<script>
import ScoreService from "@/services/score/score/ScoreService";
import ExamSession from "@/services/practical-exam/exam-session/ExamSession";
import VenueService from "@/services/practical-exam/venue/VenueService";
import LocationService from "@/services/practical-exam/location/LocationService";
import CategoryService from "@/services/product/category/CategoryService";
import InstrumentService from "@/services/product/instrument/InstrumentService";
import SyllabusService from "@/services/product/syllabus/SyllabusService";
import GradeService from "@/services/product/grade/GradeService";
import EnrolmentYearService from "@/services/setting/enrolment-year/EnrolmentYearService";
import AdminUserService from "@/services/admin-user/AdminUserService";
import SchedulerService from "@/services/practical-exam/scheduler/SchedulerService";
import ProductService from "@/services/product/ProductService";
import SchedulingRequestService from "@/services/practical-exam/scheduling-request/SchedulingRequestService";
import CreateScheduleAndAssignCandidate
  from "@/view/pages/view/practical-exam/exam-day/scheduler/start-scheduling/CreateScheduleAndAssignCandidate";

const adminUser=new AdminUserService();
const examSession = new ExamSession();
const location = new LocationService();
const venue = new VenueService();
const category = new CategoryService();
const instrument = new InstrumentService();
const syllabus = new SyllabusService();
const grade = new GradeService();
const enrolmentYear = new EnrolmentYearService();
const score = new ScoreService();
const scheduler = new SchedulerService();
const product = new ProductService();
const schedulingRequest = new SchedulingRequestService;

export default {
  components: {CreateScheduleAndAssignCandidate},
  data() {
    return {
      total: null,
      perPage: null,
      page: null,
      examSearch:null,
      selectedExam:null,
      sessionSearch:null,
      selectedSession:null,
      exam_sessions: [],
      scheduling_requests: [],
      exams: [],
      locations: [],
      categories: [],
      instruments: [],
      syllabuses: [],
      grades: [],
      enrollments: [],
      candidates: [],
      scores: [],
      selected_exam_days: [],
      sort: {
        key: '',
        isAsc: false
      },
      search:{
        exam_key: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        exam_id: '',
        session_id: '',
        grade_id: '',
        teacher_name: '',
        scheduling_request_id:'',
        scheduling_remarks: '',
        end_date: '',
        location_id: '',
        venue_id: '',
        category_id: '',
        instrument_id: '',
        syllabus: '',
        enrolment_year_id: '',
        score_id: '',
        is_active: '',
        event_type:'',
        sort_by: ''
      },
      isInstrumentLoading:false,
      isSyllabusLoading:false,
      isGradeLoading:false,
      isLoading:false,
      isContentLoading:false,
      isAssignLoading:false,
      candidate_ids:[],
      status: [

        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
      currentUserDetail:{},
      currentYear:new Date().getFullYear(),
      year:{},
      event_types:[
        {text:'All',value:'all'},
        {text:'Upcoming',value:'upcoming'},
        {text:'Closed',value:'closed'},
      ],
      examDayId: null,
      examDay: '',
      venues: [],
      isVenueLoading: false,
      isSearching: false,
      sortByValues:[
        {
          name: 'Scheduling request (ASC)',
          value: 'scheduling_request_asc'
        },
        {
          name: 'Scheduling request (DESC)',
          value: 'scheduling_request_desc'
        },
        {
          name: 'Candidate first name (ASC)',
          value: 'candidate_first_name_asc'
        },
        {
          name: 'Candidate first name (DESC)',
          value: 'candidate_first_name_desc'
        },
        {
          name: 'Teacher name (ASC)',
          value: 'teacher_name_asc'
        },
        {
          name: 'Teacher name (DESC)',
          value: 'teacher_name_desc'
        },
        {
          name: 'Grade - low to high',
          value: 'grade_low_to_high'
        },
        {
          name: 'Grade - high to low',
          value: 'grade_high_to_low'
        },
      ]
    }
  },
  mounted(){
    // this.examDayId = this.$route.params.examDayId;
    // this.getExamDayInfo();

    this.getAllScore();
    this.getAllNotScheduledCandidates();
  },
  watch:{
    examSearch(val) {
     let data = {
        name:val,
        type:'practical_exam'
      };
      product
          .searchByType(data)
          .then((response) => {
          response.data.products.map((product) => {
              let data = product;
              data.display_text = product.name;
              this.exams.push(data);
            });
          })
          .catch((err) => {

          })
          .finally(() => (this.isLoading = false));
    },

    sessionSearch(val) {
      let data = {
        name:val,
        score_id:this.search.score_id
      };
      examSession
          .search(data)
          .then((response) => {
            response.data.exam_sessions.map((session) => {
              let data = session;
              data.display_text = session.name ;
                  this.exam_sessions.push(data);
            });
          })
          .catch((err) => {

          })
          .finally(() => (this.isLoading = false));
    },
    selectedExam(val)
    {
      if(!val)
        this.search.exam_id ='';
    },

    selectedSession(val)
    {
      if(!val)
        this.search.session_id ='';
    },



  },
  computed: {

    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  methods: {
    getExamDayInfo(){
      examDay
      .show(this.examDayId)
      .then((response) => {
        this.examDay = response.data.examDay;
        this.getInstruments();
        this.getSyllabuses();
        this.getGrades();
      })
      .catch(() => {});
    },

    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    
    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },

    selectExam()
    {
      if(this.selectedExam)
        this.search.exam_id = this.selectedExam.id;
    },

    selectSession()
    {
      if(this.selectedSession)
      {
        this.search.session_id = this.selectedSession.id;
      }
      this.getCategories();
      this.getInstruments();
      this.getSyllabuses();
      this.getGrades();


    },

    getAllNotScheduledCandidates(){
        this.candidate_ids = [];
       if(this.currentUser.access_type=='federal')
       {
         if(this.search.score_id!='' && this.search.score_id!=undefined)
         {
           this.isContentLoading=true;
           this.isSearching=true;
           scheduler
               .getAll(this.search, this.page)
               .then((response) => {
                 this.candidates = response.data.data;
                 this.page = response.data.meta.current_page;
                 this.total = response.data.meta.total;
                 this.perPage = response.data.meta.per_page;
                 this.buildSelectedExamDaysData(this.candidates);
                 this.isContentLoading=false;
                 this.isSearching=false;
               })
               .catch((err) => {

               });
         }else
         {
           this.$snotify.info('Please select score');
         }

       }
       else
       {

         this.search.score_id = this.currentUser.score_id;
         this.isContentLoading=true;
         this.isAssignLoading=true;
         if(this.search.score_id!='' && this.search.score_id!=undefined) {
           scheduler
               .getAll(this.search, this.page)
               .then((response) => {
                 this.candidates = response.data.data;
                 this.page = response.data.meta.current_page;
                 this.total = response.data.meta.total;
                 this.perPage = response.data.meta.per_page;
                 this.buildSelectedExamDaysData(this.candidates);
                 this.isContentLoading = false;
                 this.isAssignLoading = false;
               })
               .catch((err) => {

               });
         }
       }

    },

    getAllLocations() {
      this.isLoading = true;
      location.getByScore(this.search.score_id).then(response => {
        this.locations = response.data;
        this.isLoading=false;
      });
    },

    getCategories(){
      category
          .getByExamSession(this.selectedSession.id, '')
          .then((res) => {
            if(res.data.data.length > 0){
              this.categories = res.data.data;
            }
            this.isInstrumentLoading = false;
          })
          .catch((err) => {
          });
    },
    
    getInstruments(){
       this.isInstrumentLoading = true;
        instrument
        .getByExamSession(this.selectedSession.id, '')
        .then((res) => {
          if(res.data.data.length > 0){
              this.instruments = res.data.data;
          }
          this.isInstrumentLoading = false;
        })
        .catch((err) => {
        });
    },

    getSyllabuses(){
      this.isSyllabusLoading = true;
      syllabus
      .getByExamSession(this.selectedSession.id, '')
      .then((res) => {
          if(res.data.data.length > 0){
              this.syllabuses = res.data.data;
          }
        this.isSyllabusLoading = false;
      })
      .catch((err) => {
      });
    },

    getGrades() {
      this.isGradeLoading = true;
      grade
      .getByExamSession(this.selectedSession.id)
      .then(res => {
        if(res.data.data.length > 0){
          this.grades = res.data.data;
        }
        this.isGradeLoading = false;
      })
      .catch((err) => {
      });
    },
    

    searchCandidates()
    {
      this.page =1;
      this.getAllNotScheduledCandidates();
    },

    getAllSchedulingRequest(){
      this.isLoading = true;
      let data = {
        score_id:this.search.score_id
      }
      schedulingRequest
          .getAllSchedulingRequest(data)
          .then(response => {
            this.isLoading = false;
            this.scheduling_requests=response.data.scheduling_requests;
          })
          .catch((err) => {

          });
    },
    getAllEnrolmentYear() {
      enrolmentYear.all().then(response => {
        this.enrollments = response.data;
        this.year = this.enrollments.filter(item => item.year == this.currentYear);
        if(this.currentUser.access_type=='score') {
          this.search.enrolment_year_id = this.currentUser.score_enrolment_year;
          this.getAllExamSession();
        }else{
          if(this.year.length>0){
            this.search.enrolment_year_id=this.year[0].id;
            this.getAllExamSession();
          }else{
            this.getAllExamSession();
          }
        }
      });
    },
    getAllScore() {
      score.getScores().then(response => {
        this.scores = response.data.scores;
      });

      if(this.currentUser.access_type=='score')
      {
        this.search.score_id = this.currentUser.score_id;
        this.getAllSchedulingRequest();
        this.getAllLocations();
        this.getAllVenues();
      }
    },
    getExamSessions(){
      this.getAllExamSession();
    },
    getCandidateSummary(examKey){
      this.$router.push({
        name: 'candidate-summary',
        params: {examKey: examKey}
      })
    },

    buildSelectedExamDaysData(candidates)
    {
      this.selected_exam_days = [];
      candidates.map((candidate) => {
        let data =
            {
              candidate_id:candidate.id,
              exam_key:candidate.exam_key,
              exam_day_id:'',
            };
        this.selected_exam_days.push(data);
      });
    },

    assignExamDays()
    {
      this.isAssignLoading = true;
      let data = [];
      this.selected_exam_days.map((exam_day) => {
        if(exam_day.exam_day_id!='' && exam_day.exam_day_id!=null)
        {
          data.push(exam_day);
        }
      });
      scheduler.assignExamDays(data).then(response=>{
        this.$snotify.success('Schedule assigned successfully.');
        this.getAllNotScheduledCandidates();
        this.isAssignLoading = false;
      });

    },

    createAndAssignExamDay()
    {
      this.$refs['create-schedule-and-assign-candidate'].openDialog(this.candidate_ids,this.search.score_id);
    },

    handleScoreChange()
    {
      this.venues = [];
      this.search.venue_id = '';
      this.locaitons = [];
      this.search.location_id = '';
      this.getAllLocations();
      this.getAllSchedulingRequest();

    },

    handleLocationChange(){
      this.isVenueLoading = true;
      this.venues = [];
      this.search.venue_id = '';
      this.getAllVenues();
      this.isVenueLoading = false;
    },

    getAllVenues(){
      venue
      .getByLocation(this.search.location_id)
      .then((res) => {
        this.venues = res.data;
      })
      .catch((err) => {

      });
    }

  }
}
</script>

<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>
